import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';

import Item2 from './Item2';
import './Services1.css';

const itemsList = [
	{
		id: 1,
		smallicon: require('./iconservices/icon_lawncare.png'),
		service: 'Lawn Care Program',
		description:
			'Lawn care is the process of providing overall care to your lawn which includes the health of turf and soil. Lawn care involves the treatment of your lawn, including fertilization, weed control, soil health, and turf disease management. Our packages incorporate various treatments such as weed control, lawn fertilizing, and core aeration for complete yard care.',
		imgservice: require('./servimages/lawncare.png'),
		taptoclose: 'Tap anywhere to close',
	},
	{
		id: 2,
		smallicon: require('./iconservices/icon_prunning.jpeg'),
		service: 'Pruning',
		description:
			'Pruning is the selective removal of certain parts of a plant by trimming of all the new growth on bushes and shrubs. It also includes the removal of diseased, demaged, dead, non-productive, structurally unsound, or otherwise unwanted plant material from crop and landscape plants.',
		imgservice: require('./servimages/pruning.png'),
		taptoclose: 'Tap anywhere to close',
	},
	{
		id: 3,
		smallicon: require('./iconservices/icon_bagsleaf.png'),
		service: 'Leaf Removal',
		description:
			'Some leaves can be mulched and left on the grass. When the mulched leaves get incorporated into the soil it acts as a natural fertilizer for the following season. However, if you chop up too many leaves without removing some, you will probably be left with dead spots in the spring and a nice layer of thatch.',
		imgservice: require('./servimages/leaf.png'),
		taptoclose: 'Tap anywhere to close',
	},
	{
		id: 4,
		smallicon: require('./iconservices/icon_leaf.png'),
		service: 'Other Services',
		description: 'Includes Snow Removal and Gutter Cleaning.',
		imgservice: require('./servimages/snow1.png'),
		taptoclose: 'Tap anywhere to close',
	},
];

const Services2 = () => {
	return (
		<AnimateSharedLayout>
			<motion.ul
				className='main_service_items'
				layout
				initial={{ borderRadius: 25 }}>
				{itemsList.map((item) => (
					<Item2
						key={item.id}
						theicon={item.smallicon}
						title={item.service}
						serviceimg={item.imgservice}
						content={item.description}
						closeit={item.taptoclose}
					/>
				))}
			</motion.ul>
		</AnimateSharedLayout>
	);
};

export default Services2;
