import React from 'react';
import { motion } from 'framer-motion';

const phonyMotion = {
	phonyAnimation: {
		x: [-20, 30],
		y: [0, 0],
		transition: {
			x: {
				repeat: Infinity,
				repeatType: 'mirror',
				duration: 1.1,
				ease: 'easeOut',
			},
			y: {
				repeat: Infinity,
				duration: 1.1,
				ease: 'easeOut',
			},
		},
	},
};

const PhoneMotion = () => {
	return (
		<div>
			<motion.div
				className='motion-items'
				variants={phonyMotion}
				animate='phonyAnimation'>
				👉🏻
			</motion.div>
		</div>
	);
};

export default PhoneMotion;
