import React from 'react';

import LogoFooter from './components/footerlogo/LogoFooter';

import Facebook from './components/socialmedia/Facebook';
import TermsBundle from './components/termsbundle/TermsBundle';
import './Footer.css';

const Footer = () => {
	return (
		<React.Fragment>
			<div className='footer_items_desktop'>
				<div className='footer_items_desktop_column'>
					<LogoFooter />
					<TermsBundle />
					<Facebook />
				</div>
			</div>
			<div className='footer_items_mobile'>
				<Facebook />
				<LogoFooter />
				<TermsBundle />
			</div>
		</React.Fragment>
	);
};

export default Footer;
