import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap/all';

import logocont from './logocontact.png';
import './LogoContact.css';

const LogoContact = () => {
	let logoconty = useRef(null);

	useEffect(() => {
		gsap.to(logoconty, {
			delay: 1.4,
			x: 0,
			y: 0,
			scaleX: 1.2,
			scaleY: 1.2,
			opacity: 1,
		});
	}, []);

	return (
		<div className='logo_contact_items'>
			<ul className='logo_contact_item_ul'>
				<li
					className='logo_contact_item_li'
					ref={(logot) => {
						logoconty = logot;
					}}>
					<img className='logo_contact__img' src={logocont} alt='free' />
				</li>
			</ul>
		</div>
	);
};

export default LogoContact;
