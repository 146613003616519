import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap/all';

import flowertwo from './about2.jpeg';
import './Flowers2.css';

const Flowers2 = () => {
	let logoItem = useRef(null);

	useEffect(() => {
		gsap.to(logoItem, {
			delay: 0.6,
			x: 0,
			y: 0,
			scaleX: 1.2,
			scaleY: 1.2,
			opacity: 1,
		});
	}, []);

	return (
		<div className='carphoto_items'>
			<ul className='carphoto_item_ul'>
				<li
					className='carphoto_item_li'
					ref={(el) => {
						logoItem = el;
					}}>
					<img className='carphoto_item_img' src={flowertwo} alt='flowertwo' />
				</li>
			</ul>
		</div>
	);
};

export default Flowers2;
