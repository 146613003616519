import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap/all';

import thetruck from './truck1.jpeg';
import './TruckContact.css';

const TruckContact = () => {
	let mytruck = useRef(null);

	useEffect(() => {
		gsap.to(mytruck, {
			delay: 1.0,
			x: 0,
			y: 0,
			scaleX: 1.2,
			scaleY: 1.2,
			opacity: 1,
		});
	}, []);

	return (
		<div className='thetruck_items'>
			<ul className='thetruck_item_ul'>
				<li
					className='thetruck_item_li'
					ref={(onetruck) => {
						mytruck = onetruck;
					}}>
					<img className='thetruck_item_img' src={thetruck} alt='suptruck' />
				</li>
			</ul>
		</div>
	);
};

export default TruckContact;
