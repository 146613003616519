import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import './Fgmbottom.css';


const Fgmbottom = () => {

	let textItem = useRef(null);

	useEffect(() => {
		gsap.to(textItem, 1.9, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 1,
		});
	});

	return (
		<div className='fgmbottom_items'>
			<h1
				className='fgmbottom_item'
				ref={(text) => {
					textItem = text;
				}}>
				F G M
			</h1>
		</div>
	);
};

export default Fgmbottom;
