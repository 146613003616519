import React from 'react';

import './TermsBundle.css';

const TermsBundle = () => {
	return (
		<div className='terms_bundle_items'>
			<div className='paragraph_items'>
				<p className='terms_date_item'>
					&copy;{new Date().getFullYear()} Fairfax Grounds Maintenance.
				</p>
				<p className='terms_date_item_p'>All rights reserved.</p>
			</div>
			<div className='powerd_web_items'>
				<p className='powerd_item'>Powered by</p>
				<a className='web_item' href='https://www.webundle.xyz'>
					WEBundle.xyz
				</a>
			</div>
		</div>
	);
};

export default TermsBundle;
