import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import './TextContent.css';

const TextContent = () => {
	let textContent = useRef(null);

	useEffect(() => {
		gsap.to(textContent, 2.3, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 0.6,
		});
	});

	return (
		<div className='textcontent_items'>
			<h1
				className='textcontent_item'
				ref={(ourtext) => {
					textContent = ourtext;
				}}>
				Fairfax Grounds Maintenance, LLC (FGM) has been in business for over 20
				years providing landscaping services in McLean, Vienna and Fairfax,
				Virginia.
			</h1>
		</div>
	);
};

export default TextContent;
