import React from 'react';

import './HeaderTitle.css';

const HeaderTitle = () => {
	return (
		<div className='headertitle_items'>
			<h1 className='headertitle_item'>FGM</h1>
		</div>
	);
};

export default HeaderTitle;
