import React from 'react';

import './Mclean.css';

const Mclean = () => {
	return (
		<div className='mclean_items'>
			<h1 className='mclean_item'>McLean - Vienna - Fairfax</h1>
		</div>
	);
};

export default Mclean;
