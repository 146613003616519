import React from 'react';

import mylogofooter from './footerlogo.png';
import './LogoFooter.css';

const LogoFooter = () => {
	return (
		<div className='logo_footer_items'>
			<img className='logo_footer_img' src={mylogofooter} alt='logofooter' />
		</div>
	);
};

export default LogoFooter;
