import React from 'react';
import { Link } from 'react-router-dom';

import './NavLinksMobile.css';

const NavLinks = (props) => {
	return (
		<div className='nav-links_items_m'>
			<ul className='main_menu_list_items_m'>
				<li className='main_menu_list_item_m'>
					<Link to='/' className='main_menu_list_item_link_m'>
						Home
					</Link>
				</li>
				<li className='main_menu_list_item_m'>
					<Link to='/ourstory' className='main_menu_list_item_link_m'>
						Our Story
					</Link>
				</li>
				<li className='main_menu_list_item_m'>
					<Link to='/contact' className='main_menu_list_item_link_m'>
						Contact
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default NavLinks;
