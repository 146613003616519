import React from 'react';

import EmailMotion from '../motion/EmailMotion';
import './PhoneHeader.css';

const PhoneHeader = () => {
	return (
		<div className='header_phone_items'>
			<EmailMotion />
			<div className='header_phone_item'>
				<p className='header_phone_call_today'>Call today</p>
				<a className='header_phone_number' href='tel:703-281-5232'>
					703-281-5232
				</a>
			</div>
		</div>
	);
};

export default PhoneHeader;
