import React from 'react';
import { motion } from 'framer-motion';

import AllText from '../components/Text/AllText';
import Flowers1 from '../components/Flowers1';
import Flowers2 from '../components/Flowers2';
import Footer from '../../shared/footer/Footer';
import './Ourstory.css';

const Ourstory = () => {
	return (
		<motion.div
			initial={{ opacity: 0, scaleX: 0 }}
			animate={{ opacity: 1, scaleX: 1 }}
			exit={{ opacity: 0, scaleX: 0 }}
			transition={{ duration: 0.5 }}>
			<section className='ourstory_items'>
				<div className='ourstory_all_text'>
					<AllText />
				</div>
				<div className='ourstory_all_flowers'>
					<div className='main_flowers1'>
						<Flowers1 />
					</div>
					<div className='main_flowers2'>
						<Flowers2 />
					</div>
				</div>
			</section>
			<div className='home_footer_div'>
				<Footer />
			</div>
		</motion.div>
	);
};

export default Ourstory;
