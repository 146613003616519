import React from 'react';

import mainLogo from './logoyellowfgm.png';
import './Logo.css';

const Logo = () => {
	return (
		<div className='logo_items'>
			<img src={mainLogo} alt='header-logo' className='logo-item' />
		</div>
	);
};

export default Logo;
