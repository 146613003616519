import React from 'react';

import { Telephone } from 'react-bootstrap-icons';
import './PhoneIcon.css';

const PhoneIcon = () => {
	return (
		<div className='icon_phone_items'>
			<a className='header_phone_number' href='tel:703-281-5232'>
				<h1 className='phone_color'>
					<Telephone />
				</h1>
			</a>
		</div>
	);
};

export default PhoneIcon;
