import React from 'react';

import Mainvideo from './fgmvideo1.mp4';
import './MainVideo.css';

const MainVideo = () => {
	return (
		<div className='video_items'>
			<video
				autoPlay
				loop
				playsInline={true}
				disablePictureInPicture={true}
				muted
				style={{
					position: 'absolute',
					width: '100%',
					left: '50%',
					top: '50%',
					height: '100%',
					objectFit: 'cover',
					transform: 'translate(-50%, -50%)',
				}}>
				<source src={Mainvideo} type='video/webm'></source>
				<source src={Mainvideo} type='video/mp4'></source>
			</video>
		</div>
	);
};

export default MainVideo;
