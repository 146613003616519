import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import './TextContent.css';

const BottomText = () => {
	let bottomContent = useRef(null);

	useEffect(() => {
		gsap.to(bottomContent, 2.3, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 1.6,
		});
	});

	return (
		<div className='textcontent_items'>
			<h1
				className='textcontent_item'
				ref={(bottomtext) => {
					bottomContent = bottomtext;
				}}>
				In the Northern Virginia area, FGM is highly regarded and well respected
				due to the longstanding relationships the business has built with its
				clientele.
			</h1>
		</div>
	);
};

export default BottomText;
