import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import './Item1.css';

const Content = ({ theicon, title, serviceimg, content, closeit }) => {
	return (
		<motion.div
			layout
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}>
			<div className='group_inside_items'>
				<div className='title_item__inside'>{title}</div>
				<div className='div_image_item__inside'>
					<img className='image_item__inside' src={serviceimg} alt='' />
				</div>
				<div className='content_item__inside'>{content}</div>
				<div className='taptocloseit_item__inside'>{closeit}</div>
			</div>
		</motion.div>
	);
};

const Item1 = ({ theicon, title, serviceimg, content, closeit }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => setIsOpen(!isOpen);

	// const minifgm = miniLogo;

	return (
		<motion.li
			className='main_service_item_li'
			layout
			onClick={toggleOpen}
			initial={{ borderRadius: 10 }}>
			<motion.div className='outside_group_items' layout>
				<h1 className='title_item_outside'>{title}</h1>
				<img className='icon_item_image' src={theicon} alt='' />
				<h1 className='tap_to_expand'>Tap to expand</h1>
			</motion.div>
			<br />
			<AnimatePresence>
				{isOpen && (
					<Content title={title} serviceimg={serviceimg} content={content} closeit={closeit} />
				)}
			</AnimatePresence>
		</motion.li>
	);
};

export default Item1;
