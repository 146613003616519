import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import './AppLoader.css';

const AppLoader = () => {
	return (
		<div className='loader_items'>
			<h3 className='loader_item_text'>Loading . . . </h3>
			<Loader
				type='ThreeDots'
				color='#004d40'
				height={80}
				width={80}
				timeout={1200}
			/>
		</div>
	);
};

export default AppLoader;
