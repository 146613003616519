import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

import './Facebook.css';

const Facebook = () => {
	return (
		<div className='social_icon_items'>
			<a
				className='icon_item_facebook'
				href='https://www.facebook.com/Fairfax-Grounds-Maintenance-llc-213517858695663/'>
				<FontAwesomeIcon icon={faFacebook} size='2x' />
			</a>
		</div>
	);
};

export default Facebook;
