import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import './MainForm.css';

const initialValues = {
	to_name: '',
	to_phone: '',
	to_email: '',
	to_address: '',
	to_city: '',
	to_zip: '',
	to_message: '',
};

const validationSchema = Yup.object({
	to_name: Yup.string().required('Required'),
	to_phone: Yup.string()
		.required('Required')
		.matches(
			/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
			'Phone number is not valid'
		),
	to_email: Yup.string().email('Invalid email format').required('Required'),
	to_address: Yup.string().required('Required'),
	to_city: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Must be only letters')
		.required('Required'),
	to_zip: Yup.string()
		.matches(/^[0-9]+$/, 'Must be only digits')
		.min(5, 'Must be exactly 5 digits')
		.max(5, 'Must be exactly 5 digits')
		.required('Required'),
	to_message: Yup.string().required('Required'),
});

const MainForm = () => {
	const [status, setStatus] = useState('');

	const onSubmit = (values, submitProps) => {
		submitProps.setSubmitting(false);
		emailjs
			.send(
				'service_lcqglsh',
				'template_6f3yaaj',
				values,
				'user_p62pdf9JDrYlCscO4AgN0'
			)
			.then(
				(response) => {
					console.log('SUCCESS!', response);
					setStatus('Submited');
				},
				(error) => {
					console.log('ERROR...', error);
				}
			);
		submitProps.resetForm();
	};

	const renderAlert = () => (
		<div
			style={{
				margin: '0',
				width: '18rem',
				borderRadius: '10px',
				transition: 'all 2s ease-out',
			}}>
			<p
				style={{
					textAlign: 'center',
					color: '#004d40',
					fontSize: '1.3rem',
					transition: 'all 2s ease-out',
				}}>
				Submited successfully! We will reply shortly.
			</p>
		</div>
	);
	useEffect(() => {
		if (status === 'Submited') {
			setTimeout(() => {
				setStatus(status);
			}, 1500);
		}
	}, [status]);

	return (
		<div className='form_main_div'>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}>
				<Form className='form_item_letvalues'>
					<div className='each_section_item_letvalues'>
						<label className='label_item_letvalues' htmlFor='name'>
							Name
						</label>
						<Field
							className='input_item_letvalues'
							type='text'
							id='username'
							placeholder='John Dow'
							name='to_name'
						/>
						<ErrorMessage name='to_name'>
							{(error) => <div className='formik_error'>{error}</div>}
						</ErrorMessage>
					</div>
					<div className='each_section_item_letvalues'>
						<label className='label_item_letvalues' htmlFor='phone'>
							Phone
						</label>
						<Field
							className='input_item_letvalues'
							type='tel'
							id='userphone'
							placeholder='123-456-7890'
							name='to_phone'
						/>
						<ErrorMessage name='to_phone'>
							{(error) => <div className='formik_error'>{error}</div>}
						</ErrorMessage>
					</div>
					<div className='each_section_item_letvalues'>
						<label className='label_item_letvalues' htmlFor='email'>
							Email
						</label>
						<Field
							className='input_item_letvalues'
							type='email'
							id='useremail'
							placeholder='john@gmail.com'
							name='to_email'
						/>
						<ErrorMessage name='to_email'>
							{(error) => <div className='formik_error'>{error}</div>}
						</ErrorMessage>
					</div>
					<div className='each_section_item_letvalues'>
						<label className='label_item_letvalues' htmlFor='address'>
							Address
						</label>
						<Field
							className='input_item_letvalues'
							id='useraddress'
							placeholder='1234 Moon Ave'
							name='to_address'
						/>
						<ErrorMessage name='to_address'>
							{(error) => <div className='formik_error'>{error}</div>}
						</ErrorMessage>
					</div>
					<div className='each_section_item_letvalues'>
						<label className='label_item_letvalues' htmlFor='city'>
							City
						</label>
						<Field
							className='input_item_letvalues'
							type='text'
							id='usercity'
							placeholder='City'
							name='to_city'
						/>
						<ErrorMessage name='to_city'>
							{(error) => <div className='formik_error'>{error}</div>}
						</ErrorMessage>
					</div>
					<div className='each_section_item_letvalues'>
						<label className='label_item_letvalues' htmlFor='zip'>
							ZIP
						</label>
						<Field
							className='input_item_letvalues'
							type='zip'
							id='userzip'
							placeholder='12345'
							name='to_zip'
						/>
						<ErrorMessage name='to_zip'>
							{(error) => <div className='formik_error'>{error}</div>}
						</ErrorMessage>
					</div>
					<div className='each_section_item_letvalues'>
						<label className='label_item_letvalues' htmlFor='message'>
							Message
						</label>
						<Field
							className='input_item_letvalues'
							as='textarea'
							id='usermessage'
							placeholder='Your message here ...'
							name='to_message'
							rows='9'
						/>
						<ErrorMessage name='to_comments'>
							{(error) => <div className='formik_error'>{error}</div>}
						</ErrorMessage>
					</div>
					<button className='button_item_letvalues' type='submit'>
						Submit
					</button>
					<hr></hr>
					{status && renderAlert()}
					<hr></hr>
				</Form>
			</Formik>
		</div>
	);
};

export default MainForm;
