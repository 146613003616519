import React, { useEffect } from 'react';
import { gsap } from 'gsap';

import './Fgm.css';

const Fgm = () => {
	const header = React.createRef();

	useEffect(() => {
		gsap.to(header.current, { color: '#76ff03', duration: 13 });
	}, [header]);

	return (
		<div className='fgm_items'>
			<h1 ref={header} className='fgm_items_text'>
				FAIRFAX GROUNDS MAINTENANCE
			</h1>
		</div>
	);
};

export default Fgm;
