import React from 'react';
import { motion } from 'framer-motion';

import AnimeText from '../components/animtext/AnimeText';
import MainForm from '../components/MainForm';

import FreeEstimate from '../components/estimate/FreeEstimate';
import MobileCall from '../components/mobilecall/MobileCall';
import TruckContact from '../components/truck/TruckContact';
import LogoContact from '../components/contactlogo/LogoContact';
import Footer from '../../shared/footer/Footer';
import './Contact.css';
import Mclean from '../components/mcleanvienna/Mclean';

const Contact = () => {
	return (
		<motion.div
			initial={{ opacity: 0, scaleX: 0 }}
			animate={{ opacity: 1, scaleX: 1 }}
			exit={{ opacity: 0, scaleX: 0 }}
			transition={{ duration: 0.5 }}>
			<section className='contact_items'>
				<div className='contact_items_all'>
					<h1 className='contact_info_text'>FAIRFAX GROUNDS MAINTENANCE</h1>
					<h1 className='contact_info_text_fgm'>(FGM)</h1>
					<Mclean />
					<MobileCall />
					<AnimeText />
					<div className='form_truck'>
						<div className='free_truck_img_items'>
							<FreeEstimate />
							<TruckContact />
							<LogoContact />
						</div>
						<div className='form_photo_items'>
							<MainForm />
						</div>
					</div>
				</div>
			</section>
			<div className='home_footer_div'>
				<Footer />
			</div>
		</motion.div>
	);
};

export default Contact;
