import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap/all';

import freestimates from './freeestimate.png';
import './FreeEsimate.css';

const FreeEstimate = () => {
	let estimattes = useRef(null);

	useEffect(() => {
		gsap.to(estimattes, {
			delay: 0.6,
			x: 0,
			y: 0,
			scaleX: 1.2,
			scaleY: 1.2,
			opacity: 1,
		});
	}, []);

	return (
		<div className='free_estimates_items'>
			<ul className='free_estimates_item_ul'>
				<li
					className='free_estimates_item_li'
					ref={(free) => {
						estimattes = free;
					}}>
					<img className='free_estimates_img' src={freestimates} alt='free' />
				</li>
			</ul>
		</div>
	);
};

export default FreeEstimate;
