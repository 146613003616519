import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import Locations from './Locations';
import Proudly from './Proudly';
import NewLogo from './NewLogo';
import './MainLocLogo.css';

const MainLocLogo = () => {
	let locProud = useRef(null);

	useEffect(() => {
		gsap.to(locProud, 1.9, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 0.5,
		});
	});

	return (
		<div className='mainloclogo_items'>
			<div
				className='main_loc_proud_item'
				ref={(group) => {
					locProud = group;
				}}>
				<Proudly />
				<Locations />
			</div>
			<div className='new_logo_item'>
				<NewLogo />
			</div>
		</div>
	);
};

export default MainLocLogo;
