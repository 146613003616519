import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap/all';
import Aos from 'aos';
import 'aos/dist/aos.css';

import supersatisfied from './ssatisfied.jpg';
import './WriteReview.css';

const WriteReview = () => {
	let reviewButton = useRef(null);

	useEffect(() => {
		gsap.to(reviewButton, {
			delay: 0.4,
			x: 0,
			y: 0,
			scaleX: 0.7,
			scaleY: 0.7,
			opacity: 1,
			repeat: -1,
			ease: 'linear',
			duration: 1.5,
		});
	}, []);

	useEffect(() => {
		Aos.init({ duration: 1600 });
	}, []);
	return (
		<div data-aos='fade-right' className='write_reviewss_items'>
			<div>
				<img
					className='satisfied_customer_img'
					src={supersatisfied}
					alt='satisfied'
				/>
			</div>
			<div className='review_button_items'>
				<a href='https://search.google.com/local/writereview?placeid=ChIJvUefQ2dJtokRxg1SpRDylrM'>
					<button
						className='review_button_main'
						ref={(revbutton) => {
							reviewButton = revbutton;
						}}>
						Write a Review
					</button>
				</a>
			</div>
		</div>
	);
};

export default WriteReview;
