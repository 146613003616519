import React from 'react';
import { motion } from 'framer-motion';

import MainVideo from '../components/video/MainVideo';
import TopCard from '../components/TopCard';
import MainTitle from '../components/MainTitle';
import MainLocLogo from '../components/newlogo/MainLocLogo';
import Landservices from '../components/landscservices/Landservices';
import Services1 from '../components/mainservices/Services1';
import Services2 from '../components/mainservices/Services2';
import Fgmbottom from '../components/Fgmbottom';
import Overtwenty from '../components/landscservices/over20years/Overtwenty';
import SpecialServices from '../components/specialservices/SpecialServices';
import SpecialServicesTitle from '../components/specialservices/specialtitle/SpecialServiceTitle';
import WriteReview from '../components/writereview/WriteReview';
import Footer from '../../shared/footer/Footer';
import './HomePage.css';

const HomePage = () => {
	return (
		<motion.div
			initial={{ opacity: 0, scaleX: 0 }}
			animate={{ opacity: 1, scaleX: 1 }}
			exit={{ opacity: 0, scaleX: 0 }}
			transition={{ duration: 0.5 }}>
			<section className='section_video_items'>
				<MainVideo />
				<TopCard>
					<MainTitle />
					<div className='mainlogo_div_items'>
						<MainLocLogo />
						<Fgmbottom />
					</div>
				</TopCard>
			</section>
			<section className='service_detailed_section'>
				<div className='landscaping_over_years'>
					<Overtwenty />
					<Landservices />
				</div>
				<div className='servicess_items_main'>
					<div className='servicess_item'>
						<Services1 />
					</div>
					<div className='servicess_item'>
						<Services2 />
					</div>
				</div>
				<div className='landscaping_over_years'>
					<SpecialServicesTitle />
				</div>
				<div className='special_services_items'>
					<SpecialServices />
				</div>
				<div className='write_review_items'>
					<WriteReview />
				</div>
				<div className='home_footer_div'>
					<Footer />
				</div>
			</section>
		</motion.div>
	);
};

export default HomePage;
