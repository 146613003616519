import React from 'react';

import './Overtwenty.css';

const Overtwenty = () => {
	return (
		<div className='overtwenty_years_items'>
			<h1 className='overtwenty_years_item'>
				Our team has over 20 years of experience building maintenance plans
				customized for each lawn
			</h1>
		</div>
	);
};

export default Overtwenty;
