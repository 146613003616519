import React from 'react';

import Fullservice from './Fullservice';
import Fgm from './Fgm';
import './MainTitle.css';

const MainTitle = () => {
	return (
		<div className='home_page_main_title_items'>
			<Fgm />
			<Fullservice />
		</div>
	);
};

export default MainTitle;
