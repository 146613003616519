import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import './Fullservice.css';

const Fullservice = () => {
	let textItem = useRef(null);

	useEffect(() => {
		gsap.to(textItem, 1.9, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 0.2,
		});
	});

	return (
		<div className='full_service_items'>
			<h1
				className='full_service_item'
				ref={(text) => {
					textItem = text;
				}}>
				FULL SERVICE LANDSCAPE COMPANY
			</h1>
		</div>
	);
};

export default Fullservice;
