import React from 'react';

import './Proudly.css';

const Proudly = () => {
	return (
		<div className='title-item_text2'>
			<h1 className='proudly_item'>PROUDLY SERVING</h1>
		</div>
	);
};

export default Proudly;
