import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';

import Item1 from './Item1';
import './Services1.css';

const itemsList = [
	{
		id: 1,
		smallicon: require('./iconservices/icon_lawnservice.jpeg'),
		service: 'Lawn Service',
		description:
			'Lawn maintenance service providers are abundant in the market but choosing the right one is tricky. Our Lawn Service General Maintenance includes lawn mowing, edging along curbs and driveways, fertilization, weed control, routine cleanup, and aeration. FGM`s standard lawn maintenance package also includes grass and small leaf blowing, skimming the pool (if you have one), and weed-eating.',
		imgservice: require('./servimages/lawnservice.jpeg'),
		taptoclose: 'Tap anywhere to close',
	},
	{
		id: 2,
		smallicon: require('./iconservices/icon_mulching.jpeg'),
		service: 'Mulching',
		description:
			'Mulching Mulch delivered and spread in specified areas. A mulch is a layer of material applied to the surface of soil. Reasons for applying mulch include conservation of soil moisture, improving fertility and health of the soil, reducing weed growth and enhancing the visual appeal of the area.',
		imgservice: require('./servimages/mulching.png'),
		taptoclose: 'Tap anywhere to close',
	},
	{
		id: 3,
		smallicon: require('./iconservices/icon_springup.jpeg'),
		service: 'Spring Clean Up',
		description:
			'During a spring clean up, the whole property gets cleaned up. Spring clean up gives your lawn and property a head start into the rest of the season. Weeds, debris, leaves, and garbage that have accumulated throughout the winter must be removed.',
		imgservice: require('./servimages/springup1.jpeg'),
		taptoclose: 'Tap anywhere to close',
	},
	{
		id: 4,
		smallicon: require('./iconservices/icon_bedmaint.jpeg'),
		service: 'Bed Maintenance',
		description:
			'We will remove or spray for weeds in all of your landscape beds, including pesky weeds growing in and between your shrubs and flowers. A well-maintained flower or shrub bed is an essential complement to your whole landscape.',
		imgservice: require('./servimages/bedmaint.png'),
		taptoclose: 'Tap anywhere to close',
	},
];

const Services1 = () => {
	return (
		<AnimateSharedLayout>
			<motion.ul
				className='main_service_items'
				layout
				initial={{ borderRadius: 25 }}>
				{itemsList.map((item) => (
					<Item1
						key={item.id}
						theicon={item.smallicon}
						title={item.service}
						serviceimg={item.imgservice}
						content={item.description}
						closeit={item.taptoclose}
					/>
				))}
			</motion.ul>
		</AnimateSharedLayout>
	);
};

export default Services1;
