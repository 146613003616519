import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

import './SpecialServices.css';

const specialPics = [
	{
		id: '1',
		title: 'Mosquito control',
		pic: require('./specialimages/ssssmosquitos.jpg'),
		content: 'Eco-friendly treatments ',
	},
	{
		id: '2',
		title: 'Firewood',
		pic: require('./specialimages/sssfire.jpeg'),
		content: 'High-quality dried firewood (oak, cherry, hickory, and more)',
	},
	{
		id: '3',
		title: 'Snow removal',
		pic: require('./specialimages/sssnow.jpg'),
		content: 'Snow removal from your sidewalks, driveways, roofs, roads',
	},
	{
		id: '4',
		title: 'Gutter Cleaninng',
		pic: require('./specialimages/ssgutter.jpg'),
		content: 'Same day quality gutter cleaning',
	},
];

const SpecialServices = () => {
	useEffect(() => {
		Aos.init({ duration: 1400 });
	}, []);

	return (
		<div className='special_images_items'>
			{specialPics.map((item) => (
				<div
					data-aos='fade-up'
					className='special_images_individual_item'
					key={item.id}>
					<div className='special_title_item'>
						<h1 className='s_title_text'>{item.title}</h1>
					</div>
					<div className='special_content_item'>
						<h1 className='s_content_text'>{item.content}</h1>
					</div>
					<div className='special_image_item_div'>
						<img className='special_image_item_image' src={item.pic} alt='' />
					</div>
				</div>
			))}
		</div>
	);
};

export default SpecialServices;
