import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';

import AppLoader from './shared/loader/AppLoader';
import MainNavigation from './shared/header/MainNavigation';
import HomePage from './homepage/pages/HomePage';
import Ourstory from './ourstory/pages/OurStory';
import Contact from './contact/pages/Contact';

function App() {
	const location = useLocation();

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 1200);
	});

	return (
		<div>
			{isLoading === true ? (
				<AppLoader />
			) : (
				<React.Fragment>
					<Helmet>
						<title>FGM</title>
						<meta
							name='description'
							content='Fairfax Grounds Maintenance - Landscape company'
						/>
						<meta
							name='keywords'
							content='lawn, lawn care, spring clean up, landscape, landscaping, mosquito, firewood, snow removal, leaf removal, gutter cleanning Fairfax Grounds Maintenance, LLC (FGM) has been in business for over 20
				years providing landscaping services in McLean, Vienna and Fairfax,
				Virginia.'
						/>
					</Helmet>
					<MainNavigation />
					<AnimatePresence exitBeforeEnter initial={false}>
						<Routes location={location} key={location.pathname}>
							<Route path='/*' element={<Navigate to='/' />}></Route>
							<Route path='/' exact element={<HomePage />}></Route>
							<Route path='/ourstory' exact element={<Ourstory />}></Route>
							<Route path='/contact' exact element={<Contact />}></Route>
						</Routes>
					</AnimatePresence>
				</React.Fragment>
			)}
		</div>
	);
}

export default App;
