import React from 'react';

import TextTitle from './TextTitle';
import TextContent from './TextContent';
import MiddleText from './MiddleText';
import BottomText from './BottomText';
import './AllText.css';

const AllText = () => {
	return (
		<div className='alltext_items'>
			<TextTitle />
			<div className='text_item'>
				<TextContent />
				<MiddleText />
				<BottomText />
			</div>
		</div>
	);
};

export default AllText;
