import React from 'react';

import PhoneIcon from '../phoneicon/PhoneIcon';
import PhoneMotion from './PhoneMotion';
import './MobileCall.css';

const MobileCall = () => {
	return (
		<div className='call_text_items'>
			<h1 className='call_text_item'>Call today</h1>
			<div className='motion_phone_mobile_items'>
				<PhoneMotion />
				<PhoneIcon />
			</div>
		</div>
	);
};

export default MobileCall;
