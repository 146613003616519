import React from 'react';
import { motion } from 'framer-motion';

const emailMotion = {
	emailAnimation: {
		x: [-20, 30],
		y: [0, 0],
		transition: {
			x: {
				repeat: Infinity,
				repeatType: 'mirror',
				duration: 0.5,
				ease: 'easeOut',
			},
			y: {
				repeat: Infinity,
				duration: 0.5,
				ease: 'easeOut',
			},
		},
	},
};

const EmailMotion = () => {
	return (
		<div>
			<motion.div
				className='motion-items'
				variants={emailMotion}
				animate='emailAnimation'>
				👉🏻
			</motion.div>
		</div>
	);
};

export default EmailMotion;
