import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap/all';

import './AnimeText.css';

const AnimeText = () => {
	let animeTexts = useRef(null);

	useEffect(() => {
		gsap.to(animeTexts, {
			delay: 0.4,
			x: 0,
			y: 0,
			scaleX: 1.2,
			scaleY: 1.2,
			opacity: 1,
		});
	}, []);

	return (
		<div className='animetext_items'>
			<ul className='animetext_items_ul'>
				<li
					className='animetext_item_li'
					ref={(anytext) => {
						animeTexts = anytext;
					}}>
					<div className='animetext_info_div'>
						<h1 className='animetext_info'>
							Please contact us with any questions you may have. We would love
							to talk with you about our services.
						</h1>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default AnimeText;
