import React, { useState } from 'react';

import MainHeader from './MainHeader';
import SideDrawer from '../utils/SideDrawer';
import Backdrop from '../utils/Backdrop';
import NavLinksMobile from './NavLinksMobile.js'
import Logo from '../header/components/logo/Logo';
import NavLinks from './NavLinks';
import PhoneHeader from './components/phone/PhoneHeader';
import './MainNavigation.css';
import HeaderTitle from './components/headertitle/HeaderTitle';

const MainNavigation = (props) => {
	const [drawerIsOpen, setDrawerIsOpen] = useState(false);

	const openDrawerHandler = () => {
		setDrawerIsOpen(true);
	};

	const closeDrawerHandler = () => {
		setDrawerIsOpen(false);
	};

	return (
		<React.Fragment>
			{drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
			<SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
				<nav className='main-navigation__drawer-nav'>
					<NavLinksMobile />
				</nav>
			</SideDrawer>
			<MainHeader>
				<div className='button_menu_items'>
					<button className='button_menu_item' onClick={openDrawerHandler}>
						M E N U
					</button>
				</div>
				<nav className='main-navigation__nav'>
					<Logo />
					<HeaderTitle />
					<PhoneHeader />
					<NavLinks />
				</nav>
			</MainHeader>
		</React.Fragment>
	);
};

export default MainNavigation;
