import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import newLogo from './logoyellowfgm.png';
import './NewLogo.css';

const NewLogo = () => {
	let newLogos = useRef(null);

	useEffect(() => {
		gsap.to(newLogos, 1.9, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 0.8,
		});
	});

	return (
		<div className='newlogo_items'>
			<img
				src={newLogo}
				alt='header-logo'
				className='newlogo_item'
				ref={(logos) => {
					newLogos = logos;
				}}
			/>
		</div>
	);
};

export default NewLogo;
