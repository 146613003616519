import React from 'react';
import { motion } from 'framer-motion';

import './Locations.css';

const mcleanTag = {
	mcleanAnimation: {
		x: [0, 140],
		y: [0, 0],
		transition: {
			x: {
				repeat: Infinity,
				repeatType: 'mirror',
				duration: 3.3,
				ease: 'easeOut',
			},
			y: {
				repeat: Infinity,
				duration: 3.3,
				ease: 'easeOut',
			},
		},
	},
};

const viennaTag = {
	viennaAnnimation: {
		x: [0, 140],
		y: [0, 0],
		transition: {
			x: {
				repeat: Infinity,
				repeatType: 'mirror',
				duration: 2.5,
				ease: 'easeOut',
			},
			y: {
				repeat: Infinity,
				duration: 2.5,
				ease: 'easeOut',
			},
		},
	},
};

const fairfaxTag = {
	fairfaxAnnimation: {
		x: [0, 140],
		y: [0, 0],
		transition: {
			x: {
				repeat: Infinity,
				repeatType: 'mirror',
				duration: 2.9,
				ease: 'easeOut',
			},
			y: {
				repeat: Infinity,
				duration: 2.9,
				ease: 'easeOut',
			},
		},
	},
};

const Locations = () => {
	return (
		<div className='locations_items'>
			<motion.h1 variants={mcleanTag} animate='mcleanAnimation'>
				McLean
			</motion.h1>
			<motion.h1 variants={viennaTag} animate='viennaAnnimation'>
				Vienna
			</motion.h1>
			<motion.h1 variants={fairfaxTag} animate='fairfaxAnnimation'>
				Fairfax
			</motion.h1>
		</div>
	);
};

export default Locations;
