import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import './TextTitle.css';

const TextTitle = () => {
	let textTitle = useRef(null);

	useEffect(() => {
		gsap.to(textTitle, 2.3, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 0.3,
		});
	});

	return (
		<div className='texttitle_items'>
			<h1
				className='texttitle_item'
				ref={(titletext) => {
					textTitle = titletext;
				}}>
				F G M
			</h1>
		</div>
	);
};

export default TextTitle;
