import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';

import './TextContent.css';

const MiddleText = () => {
	let middleContent = useRef(null);

	useEffect(() => {
		gsap.to(middleContent, 2.3, {
			opacity: 1,
			y: -12,
			ease: Power3.easeInOut,
			delay: 1,
		});
	});

	return (
		<div className='textcontent_items'>
			<h1
				className='textcontent_item'
				ref={(middletext) => {
					middleContent = middletext;
				}}>
				We provide a variety of services including, but not limited to,
				residential and commercial lawn and yard maintenance, fertilizations
				programs, seasonal clean-ups, grading, and tree and snow removal.
			</h1>
		</div>
	);
};

export default MiddleText;
