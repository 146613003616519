import React from 'react';


import './TopCard.css';

const TopCard = (props) => {
	return (
		<div className={`top-card ${props.className}`} style={props.style}>
			{props.children}
		</div>
	);
};

export default TopCard;
