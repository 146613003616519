import React from 'react';
import { motion } from 'framer-motion';

import './SpecialServiceTitle.css';

const SpecialServiceTitle = (props) => {
	return (
		<div className='landscapeservices_items'>
			<motion.h1
				className='landscapeservices_item'
				initial={{ x: -200 }}
				animate={{ x: 0 }}
				transition={{ delay: 0.2, duration: 1.3 }}>
				SPECIAL SERVICES
			</motion.h1>
		</div>
	);
};

export default SpecialServiceTitle;
